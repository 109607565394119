import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Image, Grid } from "semantic-ui-react";
import {
  Navigation,
  Footer,
  CopyrightLabel,
  SiteMap,
} from "@fishrmn/fishrmn-components";
import { Link } from "gatsby";
import "semantic-ui-less/semantic.less";
// import MessageDisplayWrapper from "./MessageDisplayWrapper";
import "./layout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFisherman {
          edges {
            node {
              businessName
              logo
            }
          }
        }
      }
    `}
    render={(queryData) => {
      const data = queryData.allFisherman.edges[0].node;

      return (
        <div className="site-container">
          <div
            style={{
              background: "#FFEA00",
              textAlign: "center",
              padding: "0.5em",
              fontSize: "0.95em",
            }}
          >
            <p style={{margin:0}}>
                To make a purchase, simply go to{' '}<a href="https://thekcfoodhub.com/" target="_blank" rel="noreferrer noopener">https://thekcfoodhub.com/</a>{' '}to place your order.
            </p>
          </div>
          {/* <MessageDisplayWrapper /> */}
          <div>
            <Navigation
              links={[
                { pageTitle: "Contact", slug: "/contact/", internal: true },
                {
                  pageTitle: "Mixed Media",
                  slug: "/mixed-media/",
                  internal: true,
                },

                {
                  pageTitle: "About Us",
                  slug: "/about-us/",
                  internal: true,
                },
                {
                  slug: "",
                  pageTitle: "",
                  internal: false,
                  className: "item dropdown",
                  dropdown: { 
                    title: "LEARN MORE",
                    options: [
                      {
                        pageTitle: "Support Our Local Farm",
                        slug: "/support-our-local-farm/",
                        internal: true,
                      },
                      {
                        pageTitle: "Donate Real Estate",
                        slug: "/donate-real-estate/",
                        internal: true,
                      },
                      {
                        pageTitle: "Jobs",
                        slug: "/jobs/",
                        internal: true,
                      },
                      {
                        pageTitle: "Sponsors",
                        slug: "/sponsors/",
                        internal: true,
                      },
                      {
                        pageTitle: "Volunteer",
                        slug: "/volunteer/",
                        internal: true,
                      },
                      {
                        pageTitle: "Plant Donations",
                        slug: "/plant-donations/",
                        internal: true,
                      },
                      {
                        pageTitle: "Facebook",
                        slug: "https://www.facebook.com/opheliasbluevine/",
                        internal: false,
                      },
                      {
                        pageTitle: "Instagram",
                        slug: "https://www.instagram.com/opheliasbluevine/",
                        internal: false,
                      },
                    ],
                  },
                },
                // {
                //   pageTitle: "SUN FRESH ORDERS",
                //   slug: "/wholesale/",
                //   internal: true,
                //   className: "",
                // },
                // {
                //   pageTitle: "HYVEE ORDERS",
                //   slug: "/wholesale/",
                //   internal: true,
                //   className: "hyvee-order",
                // },
                {
                  pageTitle: "DOORDASH",
                  slug: "https://www.doordash.com/store/ophelia's-blue-vine-kansas-city-24270426/?pickup=false",
                  internal: false,
                  className: "",
                },
              ]}
              backgroundColor={"white"}
              bordered={false}
              centerMobileNavItems={true}
              evenSpacing={true}
              fixed={false}
              header={data.businessName}
              linksAs={"h4"}
              logo={data.logo}
              primaryContentCentered={false}
              internalLinkComponent={Link}
            />
          </div>
          <div className="page-container">{children}</div>
          <Footer
            backgroundColor={"primary"}
            horizontalAlign={"center"}
            verticalAlign={"middle"}
            columns={[
              <CopyrightLabel
                company={data.businessName}
                phrase={"All Rights Reserved"}
              />,
              <SiteMap
                links={[
                  { pageTitle: "Home", slug: "/", internal: true },

                  { pageTitle: "Contact", slug: "/contact/", internal: true },
                  {
                    pageTitle: "Mixed Media",
                    slug: "/mixed-media",
                    internal: true,
                  },
                  {
                    pageTitle: "Donate Real Estate",
                    slug: "/donate-real-estate/",
                    internal: true,
                  },
                  {
                    pageTitle: "Jobs",
                    slug: "/jobs/",
                    internal: true,
                  },
                  {
                    pageTitle: "About Us",
                    slug: "/about-us",
                    internal: true,
                  },
                  {
                    pageTitle: "Volunteer",
                    slug: "/volunteer/",
                    internal: true,
                  },
                  // {
                  //   pageTitle: "SUN FRESH ORDERS",
                  //   slug: "/wholesale/",
                  //   internal: true,
                  //   className: "hyvee-order",
                  // },
                  // {
                  //   pageTitle: "HYVEE ORDERS",
                  //   slug: "/wholesale/",
                  //   internal: true,
                  // },
                  {
                    pageTitle: "DOORDASH",
                    slug: "https://www.doordash.com/store/ophelia's-blue-vine-kansas-city-24270426/?pickup=false",
                    internal: false,
                    className: "",
                  },
                ]}
                horizontal={true}
                bulleted={false}
                internalLinkComponent={Link}
              />,
              <div>
                <Grid
              column={2}
              centered
              verticalAlign="middle"
              stackable
              >
                <Grid.Column textAlign="center" width="6">
                <a href="http://www.fliprogram.com" target="_blank">
                  <img
                    src="
https://www.fliprogram.com/uploads/seals/site-seal_128x128 White.png"
                    alt="FLIP Insurance Seal"
                    width="128px"
                    height="128px"
                  />
                </a>
                </Grid.Column>
                <Grid.Column textAlign="center" width="6">
                <a href="https://secure.givelively.org/donate/generating-income-for-tomorrow" target="_blank">
                  <img
                    src="https://fisherman.gumlet.io/public/opheliasbluevine/img.png"
                    alt="generating income for tomorrow"
                    width="128px"
                    height="128px"
                  />
                </a>
                </Grid.Column>
              </Grid>
              </div>,
            ]}
          />
        </div>
      );
    }}
  />
);
